import React, { useContext, useState, useEffect } from "react";
import headset from "../img/headset.png";
import twitter from "../img/twitter.png";
import facebook from "../img/facebook.png";
import instagram from "../img/instagram.png";
import youtube from "../img/youtube.png";
import linkedin from "../img/linkedin.png";
import email from "../img/email.png";
import location from "../img/location.png";
import { ContactContext } from '../ContactContext';

function Header() {
  const { contact } = useContext(ContactContext);
  const { socialNetwork } = useContext(ContactContext);
  return (
    <>
      <div className="row menu">
        <div className="col-md-4"></div>
        <div className="col-md-4 col-sm-8">
          <div className="align text-center">
            <div className="telefono">
              {contact.telefono ? (
                <a className="telefono">
                  <img width="40px" src={headset} alt="" />
                </a>
              ) : (
                ""
              )}
              ({contact.telefono})
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="align text-center">
            {socialNetwork.twitter ? (
              <a
                href={socialNetwork.twitter}
                target="_blank"
                className="twitter"
              >
                <img width="25px" src={twitter} alt="" />
              </a>
            ) : (
              ""
            )}
            {socialNetwork.facebook ? (
              <a
                href={socialNetwork.facebook}
                target="_blank"
                className="facebook"
              >
                <img className="redes" width="25px" src={facebook} alt="" />
              </a>
            ) : (
              ""
            )}
            {socialNetwork.instagram ? (
              <a
                href={socialNetwork.instagram}
                target="_blank"
                className="instagram"
              >
                <img className="redes" width="25px" src={instagram} alt="" />
              </a>
            ) : (
              ""
            )}
            {socialNetwork.youtube ? (
              <a
                href={socialNetwork.youtube}
                target="_blank"
                className="youtube"
              >
                <img className="redes" width="25px" src={youtube} alt="" />
              </a>
            ) : (
              ""
            )}
            {socialNetwork.linkedin ? (
              <a
                href={socialNetwork.linkedin}
                target="_blank"
                className="linkedin"
              >
                <img className="redes" width="25px" src={linkedin} alt="" />
              </a>
            ) : (
              ""
            )}
            {contact.email ? (
              <a href={`mailto:${contact.email}`} className="email">
                <img className="redes" width="25px" src={email} alt="" />
              </a>
            ) : (
              ""
            )}
            {socialNetwork.ubicacion ? (
              <a
                href={socialNetwork.ubicacion}
                target="_blank"
                className="ubicacion"
              >
                <img className="redes" width="25px" src={location} alt="" />
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Header;
