import React, { useContext } from "react";
import fondohps from "../img/fondohps.jpg";
import sadiv1 from "../img/sadiv1.jpg";
import sadiv2 from "../img/sadiv2.jpg";
import sadiv4 from "../img/sadiv4.jpg";
import { ContactContext } from '../ContactContext';

function Carrousel() {
  const { carrousel } = useContext(ContactContext);
console.log(carrousel);
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="align text-center">
          <div
            id="carouselExampleDark"
            className="carousel carousel-dark slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active" data-bs-interval="10000">
                <img src={fondohps} className="d-block w-100" alt="" />
                {carrousel.titulo_1 || carrousel.descripcion_1 ? (
                  <div className="carousel-caption d-none d-md-block Carrousel1">
                    <h1>{carrousel.titulo_1}</h1>
                    <p>{carrousel.descripcion_1}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="carousel-item" data-bs-interval="2000">
                <img src={sadiv1} className="d-block w-100" alt="" />
                {carrousel.titulo_2 || carrousel.descripcion_2 ? (
                  <div className="carousel-caption d-none d-md-block Carrousel1">
                    <h1>{carrousel.titulo_2}</h1>
                    <p>{carrousel.descripcion_2}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="carousel-item">
                <img src={sadiv2} className="d-block w-100" alt="" />
                {carrousel.titulo_3 || carrousel.descripcion_3 ? (
                  <div className="carousel-caption d-none d-md-block Carrousel1">
                    <h1>{carrousel.titulo_3}</h1>
                    <p>{carrousel.descripcion_3}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="carousel-item">
                <img src={sadiv4} className="d-block w-100" alt="" />
                {carrousel.titulo_4 || carrousel.descripcion_4 ? (
                  <div className="carousel-caption d-none d-md-block Carrousel1">
                    <h1>{carrousel.titulo_4}</h1>
                    <p>{carrousel.descripcion_4}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Carrousel;
