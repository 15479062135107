import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Url } from "../config/url";
import { api_token } from "../session";
import { ContactContext } from '../ContactContext';

function Footer() {
  const { contact } = useContext(ContactContext);
  const { footer } = useContext(ContactContext);
  // const [error, setError] = useState(null);
  // const [isLoaded, setIsLoaded] = useState(false);
  // const [items, setItems] = useState([]);
  // useEffect(() => {
  //   fetch(`${Url}webconfigschedule`, {
  //     headers: {
  //       Accept: "application/json",
  //       Authorization: `Bearer ${api_token}`,
  //     }
  //   })
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         //console.log(result);
  //         setIsLoaded(true);
  //         setItems(result);
  //       },
  //       (error) => {
  //         setIsLoaded(true);
  //         setError(error);
  //       }
  //     );
  // }, []);

  // if (error) {
  //   return <div>Error: {error.message}</div>;
  // } else if (!isLoaded) {
  //   return <div>Loading...</div>;
  // }

  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="footer-info">
                <h3>Información</h3>
                <p>
                  {contact.telefono ? (
                    <a href={contact.telefono} className="telefono1"></a>
                  ) : (
                    ""
                  )}
                  {contact.telefono}
                  <br />
                  {contact.localidad ? (
                    <a href={contact.localidad} className="localidad"></a>
                  ) : (
                    ""
                  )}
                  {contact.localidad}
                  <br />
                  {contact.email ? (
                    <a href={contact.email} className="email"></a>
                  ) : (
                    ""
                  )}
                  {contact.email}
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 footer-links">
              <div className="footer-info">
                <h3>Nuestros horarios de atención</h3>
                <div className="align text-start">
                  <ul>
                    <p>
                      {footer.map((item) => (
                        <li key={item.id}>
                          <span>
                            <b>{item.detalle}: &nbsp;</b>
                          </span>{" "}
                          {item.horario}
                        </li>
                      ))}
                    </p>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6 footer-newsletter">
              <h4>Our Newsletter</h4>
              <p>
                Tamen quem nulla quae legam multos aute sint culpa legam noster
                magna
              </p>
              <form action="" method="post">
                <input type="email" name="email" />
                <input type="submit" value="Subscribe" />
              </form>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
