import React from 'react';

export function BotonesEspecialidades(props) {
    return (
        <button id={props.id} 
        value={props.value}
        onClick={props.eventoEspecialidad}
        className="especialidad-boton text-start fw-bold gris fade-in-element">{props.especialidad}</button>
    );
}

export function FlechasPaginacion(props){
    return (
        <div className="d-flex justify-content-evenly align-items-center mt-4">
            <button onClick={props.prevHandler} disabled={props.disabledAtras} id={props.flechaAtras} className="NoStyles"><i className="bi bi-arrow-left especialidades-flecha"></i></button>
            <p>{props.paginaActual}/{props.paginaFinal}</p>
            <button onClick={props.nextHandler} disabled={props.disabledSiguiente} id={props.flechaSiguiente} className="NoStyles"><i className="bi bi-arrow-right especialidades-flecha"></i></button>
        </div>
    )
        
}

export function Tarjeta(props) {
    return (
      <div className="tarjeta fade-in-element">
        <div className="resultados-carta">
          <div className="redes-tarjeta">
            {props.facebook && (
              <a href={props.facebook} style={{ textDecoration: 'none' }} target="_blank">
                <i className="red bi bi-facebook"></i>
              </a>
            )}
            {props.twitter && (
              <a href={props.twitter} style={{ textDecoration: 'none' }} target="_blank">
                <i className="red bi bi-twitter"></i>
              </a>
            )}
            {props.instagram && (
              <a href={props.instagram} style={{ textDecoration: 'none' }} target="_blank">
                <i className="red bi bi-instagram"></i>
              </a>
            )}
            {props.linkedin && (
              <a href={props.linkedin} style={{ textDecoration: 'none' }} target="_blank">
                <i className="red bi bi-linkedin"></i>
              </a>
            )}
          </div>
          <img src={props.foto} className="card-img-top" alt={props.descripcion} />
        </div>
        <div className="card-body">
          <p className="card-text text-center mt-2">{props.nombre}</p>
        </div>
      </div>
    );
  }

export function Descripcion(props){
  return(
  <div className="row justify-content-between">
  <div className="flex-column text-center gris ">
  </div>
    <div className="mt-4 fade-in-element">
      <h2 className="gris text-center">{props.titulo}</h2>
      <p className="gris text-center fw-bolder">{props.descripcion}</p>
        <p className="text-center fw-bolder rojo-texto">Nuestros profesionales en {props.titulo} en el Hospital Privado SADIV</p>
    </div>
</div>
)}
