import React from "react";
import logohps from "../img/logohps.png";
import WhatsAppButtonConsultorios from "../img/WhatsAppButtonConsultorios.jpeg"
import WhatsAppButtonDxi from "../img/WhatsAppButtonDxi.jpeg"
import WhatsAppButtonOdontologia from "../img/WhatsAppButtonOdontologia.jpeg"
import $ from 'jquery'; // <-to import jquery

function Menu() {
    // let location = useLocation();
    const refresh = () => window.location.replace('https://new.hpsadiv.com')

    return (
        <>
            <form
                onSubmit={(ev) => {
                    ev.preventDefault();
                    function ascii_to_hexa(str) {
                        var arr1 = [];
                        for (var n = 0, l = str.length; n < l; n++) {
                            var hex = Number(str.charCodeAt(n)).toString(16);
                            arr1.push(hex);
                        }
                        return arr1.join("");
                    }
                    var accn = document.getElementById("field_codigo").value;
                    var patientid = document.getElementById("field_documento").value;
                    //   var zero = "000000000000000"; // hola
                    var zero = "XXXXXXXXXXXXXXX";
                    patientid = zero + patientid;
                    patientid = patientid.substring(patientid.length - 15);
                    var id = ascii_to_hexa(patientid + accn).toUpperCase();
                    if (document.getElementById("field_documento").value) {
                        // window.open(`https://estudio.informemedico.com.ar/#/109/${id}`);
                        // var win = window.open("https://sadiv.informemedico.com.ar/pacientes/detalle.html?hash=${id}", '_blank');
                        var win = window.open(`https://sadiv.informemedico.com.ar/pacientes/detalle.html?hash=${id}`, '_blank');
                        $("#exampleModal").find("input").val("");
                    }
                }}
            >

                <div
                    className="modal fade show d-block"
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title" id="exampleModalLabel">
                                    <h1>Mis estudios</h1>
                                </div>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={refresh}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <p>
                                                <b>Historia Clínica</b>
                                            </p>
                                            <input
                                                className="inputs"
                                                id="field_documento"
                                                name="field_documento"
                                                type="text"
                                            ></input>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <p>
                                                <b>Código</b>
                                            </p>
                                            <input
                                                className="inputs"
                                                id="field_codigo"
                                                name="field_codigo"
                                                type="text"
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="align text-center">
                                            <div className="boton">
                                                <button type="submit" className="btn btn-primary">
                                                    Ver estudio
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div
                className="modal fade"
                id="turnosModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title" id="exampleModalLabel">
                                <h1>Solicitá tu turno</h1>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="row">
                                    <div className="align text-center">
                                        <a
                                            target="_blank"
                                            href="http://turnos.hpsadiv.com/TurnosOnlineWeb/"
                                        >
                                            <button className="turnoshis-btn">Turnos Web</button>
                                        </a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="align text-center">
                                        <a target="_blank" aria-label="Consultorios" href="https://wa.me/5493329602559"> <img alt="Chat on WhatsApp" src={WhatsAppButtonConsultorios} /> </a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="align text-center">
                                        <div className="boton">
                                            <a target="_blank" aria-label="Diagnóstico por Imágenes" href="https://wa.me/5493329602374"> <img alt="Chat on WhatsApp" src={WhatsAppButtonDxi} /> </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="align text-center">
                                        <div className="boton">
                                            <a target="_blank" aria-label="Odontología" href="https://wa.me/5493329615001"> <img alt="Chat on WhatsApp" src={WhatsAppButtonOdontologia} /> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-md-3">
                    <div className="align text-center">
                        <img className="Logo" src={logohps} alt="" />
                    </div>
                </div>
                <div className="col-xs-6 col-md-3">
                    <div className="align text-center">
                        <button
                            className="appointment-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                        >
                            MIS ESTUDIOS
                        </button>
                        <button
                            className="appointment-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#turnosModal"
                        >
                            TURNOS
                        </button>
                    </div>
                </div>
                {/* <div className="col-xs-6 col-md-6">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      aria-current="page"
                      href=""
                    ></a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      aria-current="page"
                      href="#"
                    ></a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link active"></a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link active"></a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div> */}
            </div>
            {/* {location.pathname == '/mis-estudios' ? (
            showModal()
                ): (
                  ""
                )} */}

        </>
    );

}
export default Menu;
