import React, { useState, useEffect } from "react";
import 'bootstrap';
import '../css/servicios.css';
import { BotonesEspecialidades, FlechasPaginacion, Tarjeta, Descripcion } from './servicios/elementos';
import axios from 'axios';
import { Url } from '../config/url'
import { api_token } from "../session";

const ItemsPorPaginaEsp = (11);
const ItemsPorPagina = (6);

const Servicios = () => {
  ///Especialidades////
  const [loadingEsp, setLoadingEsp] = useState(true);
  const [especialidades, setEspecialidades] = useState([]);
  const [paginaFinalEsp, setpaginaFinalEsp] = useState(0);
  const [itemInicialEsp, setitemInicialEsp] = useState(0);
  const [itemFinalEsp, setitemFinalEsp] = useState(11);
  const [paginaActualEsp, setpaginaActualEsp] = useState(1);
  const [sliceEspecialidades, setsliceEspecialidades] = useState([]);
  const [habilitarAtrasEsp, setHabilitarAtrasEsp] = useState(true);
  const [habilitarSiguienteEsp, setHabilitarSiguienteEsp] = useState(true);

  ////Resultados////
  var [doctoresPorEsp, setDoctoresPorEsp] = useState([]);
  var [doctoresPorNombre, setDoctoresPorNombre] = useState([]);
  var [arrayDoctores, setArrayDoctores] = useState([]);
  const [hayResultados, setHayResultados] = useState(false);
  const [sinResultados, setSinResultados] = useState("");
  const [paginaFinal, setpaginaFinal] = useState(0);
  const [itemInicial, setitemInicial] = useState(0);
  const [itemFinal, setitemFinal] = useState(6);
  const [paginaActual, setpaginaActual] = useState(1);
  const [sliceProf, setSliceProf] = useState([doctoresPorEsp]);
  const [habilitarAtras, setHabilitarAtras] = useState(true);
  const [habilitarSiguiente, setHabilitarSiguiente] = useState(true);
  const [selectedMedico, setSelectedMedico] = useState(null);
  const [loading, setLoading] = useState(true);
  const [busqueda, setBusqueda] = useState();
  const [search, setSearch] = useState();
  const [datosEspecialidad, setDatosEspecialidad] = useState();


  ///Especialidades///
  useEffect(() => {
    const fetchEspecialidades = async () => {
      try {
        const response = await axios.get(`${Url}especialidades/showEspecialidades`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${api_token}`,
          }
        });
        setEspecialidades(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchEspecialidades();
  }, []);

  /// Resultados ///
  useEffect(() => {
    const fetchDoctoresNombre = async () => {
      if (!search) return;

      setLoading(true);
      try {
        const response = await axios.get(`${Url}doctors/getdoctorsbyespecialidad?filter[aparicion_web]=1&&filter[doctor_by_name]=${search}`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${api_token}`,
          }
        });
        setDoctoresPorNombre(response.data.data)
      } catch (error) {
        setDoctoresPorNombre([]);
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };
    fetchDoctoresNombre();
  }, [search]);

  useEffect(() => {

    const fetchDoctoresEspecialidad = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Url}doctors/getdoctorsbyespecialidad?filter[aparicion_web]=1&&filter[especialidad]=${datosEspecialidad.id}`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${api_token}`,
          }
        });

        setDoctoresPorEsp(response.data.data)

      } catch (error) {
        setDoctoresPorEsp([]);
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };
    fetchDoctoresEspecialidad();

  }, [datosEspecialidad]);

  ///EFECTOS///

  ///Efectos Resultados///

  useEffect(() => {
    if (doctoresPorEsp && doctoresPorEsp.length > 0) {
      setArrayDoctores([]);
      setArrayDoctores(doctoresPorEsp);

    } else if
      (doctoresPorNombre && doctoresPorNombre.length > 0) {
      setArrayDoctores([]);
      setArrayDoctores(doctoresPorNombre);

    } else {
      setArrayDoctores([]);
    }
  }, [doctoresPorNombre, doctoresPorEsp]);

  useEffect(() => {
    setpaginaFinal(Math.ceil(arrayDoctores.length / ItemsPorPagina));
  }, [arrayDoctores]);

  useEffect(() => {
    setSliceProf(arrayDoctores.slice(itemInicial, itemFinal));
  }, [arrayDoctores, itemInicial, itemFinal]);

  useEffect(() => {
    if (paginaActual < 2) {
      setHabilitarAtras(false);
    } else {
      setHabilitarAtras(true);
    }

    if (paginaActual === paginaFinal) {
      setHabilitarSiguiente(false);
    } else {
      setHabilitarSiguiente(true);
    }
  }, [paginaActual, paginaFinal]);

  useEffect(() => {
    // ...

    if (doctoresPorNombre.length == 0) {
      setHayResultados(true);
    } else {
      setHayResultados(false);
    }
  }, [doctoresPorNombre]);

  useEffect(() => {
    if (doctoresPorEsp.length == 0) {
      setHayResultados(true);
    } else {
      setHayResultados(false);
    }
  }, [doctoresPorEsp]);

  useEffect(() => {
    if (busqueda == "") {
      setBusqueda(undefined);
    }
  }, [busqueda]);

  ///////////////


  /// Efectos Especialidades ////
  useEffect(() => {
    setpaginaFinalEsp(Math.ceil(especialidades.length / ItemsPorPaginaEsp));
  }, [especialidades]);


  useEffect(() => {
    setsliceEspecialidades(especialidades.slice(itemInicialEsp, itemFinalEsp));
  }, [especialidades, itemInicialEsp, itemFinalEsp]);


  useEffect(() => {
    if (paginaActualEsp < 2) {
      setHabilitarAtrasEsp(false);
    } else {
      setHabilitarAtrasEsp(true);
    }

    if (paginaActualEsp === paginaFinalEsp) {
      setHabilitarSiguienteEsp(false);
    } else {
      setHabilitarSiguienteEsp(true);
    }
  }, [paginaActualEsp, paginaFinalEsp]);


  ///Funciones Especialidades///
  function siguienteEsp() {
    setitemInicialEsp(itemInicialEsp + ItemsPorPaginaEsp);
    setitemFinalEsp(itemFinalEsp + ItemsPorPaginaEsp);
    setpaginaActualEsp(paginaActualEsp + 1);
  }
  function anteriorEsp() {
    setitemInicialEsp(itemInicialEsp - ItemsPorPaginaEsp);
    setitemFinalEsp(itemFinalEsp - ItemsPorPaginaEsp);
    setpaginaActualEsp(paginaActualEsp - 1);
  }

  function filtroPorEspecialidad(especialidadSeleccionada) {

    setSearch()
    setBusqueda()

    setDatosEspecialidad(especialidades.find(objeto => objeto.id == especialidadSeleccionada));

    setSinResultados("No hay resultados")
  }

  ////////////////////

  ///Funciones Resultados///

  const searcher = (event) => {
    if (event.key === 'Enter') {
      setSearch(busqueda);
      setBusqueda("");
      setDatosEspecialidad();
      setSearch(busqueda);
      setSinResultados("No hay resultados");
    }
  }

  const searcherButton = () => {
    setSearch(busqueda);
    setBusqueda("");
    setDatosEspecialidad();
    setSearch(busqueda);
    setSinResultados("No hay resultados");
  }


  function nextHandler() {
    setitemInicial(itemInicial + ItemsPorPagina);
    setitemFinal(itemFinal + ItemsPorPagina);
    setpaginaActual(paginaActual + 1);
  }

  function prevHandler() {
    setitemInicial(itemInicial - ItemsPorPagina);
    setitemFinal(itemFinal - ItemsPorPagina);
    setpaginaActual(paginaActual - 1);
  }


  const openModal = (coincidente) => {
    setSelectedMedico(coincidente);
  };
  const closeModal = () => {
    setSelectedMedico(null);
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  const redirectToWhatsApp = () => {
    const phoneNumber = '5493329602559';
    const message = `Hola, quisiera solicitar un turno para el/la profesional ${selectedMedico.name}`;
    const encodedMessage = encodeURIComponent(message);
    const linkwp = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(linkwp, '_blank');
  };

  //////

  return (
    <div id="servicios" className="body fade-in-element">
      <div className="container">

        <div>
          {datosEspecialidad && (
            <Descripcion
              titulo={datosEspecialidad.especialidad}
              descripcion={datosEspecialidad.descripcion}
            />
          )}
        </div>

        <div className="select-responsive">
          <div>
            <div className="flex-column text-center gris">
              <div className="Especialidades mt-2">
                <h3 className="fw-bold text-start t">Búsqueda por Especialidad:</h3>
                <h5 className="especialidad-titulo fw-bold text-start">ESPECIALIDADES:</h5>
                <div id="ListadoEspecialidades"></div>
                <div>
                  <div>

                    <div className="ocultar-select">
                      <select className="especialidades-select" onChange={(e) => filtroPorEspecialidad(e.target.value)}>
                        <option>
                          Elija una especialidad
                        </option>
                        {especialidades.map((especialidad) => (
                          <option key={especialidad.especialidad} value={especialidad.id}>
                            {especialidad.especialidad}
                          </option>
                        ))}
                      </select>
                    </div>




                    <div className="ocultar-listado">
                      {sliceEspecialidades.map((especialidad) => {
                        return (
                          <div key={especialidad.especialidad} className="fade-in-element">
                            <BotonesEspecialidades
                              key={especialidad.id}
                              id={especialidad.especialidad}
                              value={especialidad.id}
                              eventoEspecialidad={() => filtroPorEspecialidad(especialidad.id)}
                              especialidad={especialidad.especialidad}
                            ></BotonesEspecialidades>
                          </div>
                        );
                      })}
                    </div>



                    <div className="mb-5 ocultar-listado">
                      <FlechasPaginacion
                        flechaAtras={'flechaAtras'}
                        flechaSiguiente={"flechaSiguiente"}
                        disabledAtras={!habilitarAtrasEsp}
                        disabledSiguiente={!habilitarSiguienteEsp}
                        prevHandler={anteriorEsp} nextHandler={siguienteEsp}
                        paginaActual={paginaActualEsp} paginaFinal={paginaFinalEsp}></FlechasPaginacion>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="Resultados mt-2">
            <h3 className="fw-bold text-center gris">Resultados:</h3>
            <div>
              <div>
                {selectedMedico && (
                  <div
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      background: "rgba(0, 0, 0, 0.5)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 1000,
                    }}
                    onClick={handleOutsideClick}
                  >
                    <div
                      style={{
                        background: "#fff",
                        padding: "20px",
                        borderRadius: "4px",
                      }}
                    >
                      <div className="d-flex flex-row-reverse">
                        <div>
                          <button className="legajo-boton" onClick={closeModal}><i className="bi bi-x-circle legajo-boton"></i></button>
                        </div>
                        <div>
                          <div className="mb-3">
                            <div className="row g-0">
                              <div className="col-md-4 container">
                                <div className="legajo-contenedor-img">
                                  <img src={selectedMedico.picprofile}
                                    className="legajo-img rounded-start" alt="Foto de Perfil Doctor" />
                                </div>
                                <div className="mt-1 d-flex justify-content-evenly">
                                  {selectedMedico.facebook && (
                                    <a href={selectedMedico.facebook} style={{ textDecoration: 'none' }} target="_blank">
                                      <i className="red bi bi-facebook"></i>
                                    </a>
                                  )}
                                  {selectedMedico.twitter && (
                                    <a href={selectedMedico.twitter} style={{ textDecoration: 'none' }} target="_blank">
                                      <i className="red bi bi-twitter"></i>
                                    </a>
                                  )}
                                  {selectedMedico.instagram && (
                                    <a href={selectedMedico.instagram} style={{ textDecoration: 'none' }} target="_blank">
                                      <i className="red bi bi-instagram"></i>
                                    </a>
                                  )}
                                  {selectedMedico.linkedin && (
                                    <a href={selectedMedico.linkedin} style={{ textDecoration: 'none' }} target="_blank">
                                      <i className="red bi bi-linkedin"></i>
                                    </a>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-8">
                                <div className="pb-3 legajo-centrar-resp legajo-margin">
                                  <h5>{selectedMedico.name}</h5>
                                  {selectedMedico.matricula && (
                                    <p className="legajo-matricula">Mat.{selectedMedico.matricula}</p>
                                  )}
                                  <h6 className="fw-bold rojo-texto">Especialidades</h6>
                                  <p className="listas-tarjetas">
                                    {selectedMedico.especialidades.map((especialidad, index) => (
                                      <p key={index}>{especialidad.especialidad}</p>
                                    ))
                                    }
                                  </p>
                                  {selectedMedico.antecedentes && (
                                    <div>
                                      <h6 className="fw-bold rojo-texto">Antecedentes</h6>
                                      <p className="listas-tarjetas">
                                        {selectedMedico.antecedentes.map((antecedente, index) => (
                                          <p key={index}>{antecedente.antecedente}</p>
                                        ))
                                        }
                                      </p>
                                    </div>
                                  )}
                                  <div>
                                    <button onClick={redirectToWhatsApp} className="NoStyles legajo-botonWP">
                                      <div className="d-flex flex-row align-items-center">
                                        <i className="bi bi-whatsapp legajo-botonWP-contenido"></i>
                                        <p className="legajo-botonWP-contenido">&nbsp;&nbsp;Solicitar un Turno</p>
                                      </div>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <div className="">
                  <div className="d-flex flex-row">

                    <input onChange={(e => setBusqueda(e.target.value))} value={busqueda} className="especialidad-searchbar" type="text"
                      onKeyUp={searcher} placeholder="Buscar por nombre de profesional">

                    </input>
                    <button onClick={searcherButton} className="fw-bold especialidad-searchbutton" type="submit">BUSCAR</button>
                  </div>

                  <div className="d-flex justify-content-center">

                    {loading ? (
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <div>
                        {sliceProf.length > 0 ? (
                          <div className="grid mt-2">
                            {sliceProf.map((coincidente) => (
                              <button
                                onClick={() => openModal(coincidente)}
                                className="NoStyles"
                                key={coincidente.id}
                              >
                                <Tarjeta
                                  key={coincidente.id}
                                  facebook={coincidente.facebook}
                                  twitter={coincidente.twitter}
                                  instagram={coincidente.instagram}
                                  linkedin={coincidente.linkedin}
                                  foto={coincidente.picprofile}
                                  descripcionfoto={"foto de perfil doctor"}
                                  nombre={coincidente.name}
                                />
                              </button>
                            ))
                            }
                          </div>
                        ) : (
                          <div>
                            <br></br>
                            <br></br>
                            {hayResultados && <h5>{sinResultados}</h5>}
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div>
                    {sliceProf.length !== 0 && (
                      <div className="fade-in-element">
                        <FlechasPaginacion
                          flechaAtras={'flechaAtras'}
                          flechaSiguiente={"flechaSiguiente"}
                          disabledAtras={!habilitarAtras}
                          disabledSiguiente={!habilitarSiguiente}
                          prevHandler={prevHandler} nextHandler={nextHandler}
                          paginaActual={paginaActual} paginaFinal={paginaFinal}></FlechasPaginacion>
                      </div>
                    )}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
export default Servicios;