
import Footer from "./components/footer";
import Header from "./components/header";
import Menu from "./components/menu";
import Carrousel from "./components/Carrousel";
import Misestudios from "./components/misestudios";
import Servicios from "./components/Servicios"
import "./App.css";
import "./css/footer.css";
import "./css/estudios.css";
import "./css/header.css";
import "./css/menu.css";
import { ContactProvider } from './ContactContext'; 

import { BrowserRouter as Router, Route, Routes  } from "react-router-dom";
function App() {
  return (
    <ContactProvider>
    <Router>
      <Routes> 
        <Route path="/" element={<>
                                  <Header/>
                                  <Menu/>
                                  <Carrousel/>
                                  <Servicios/>
                                  <Footer/>
                                </>
        }/>
        <Route path="/mis-estudios" element={<>
                                  <Header/>
                                  <Misestudios/>
                                  <Carrousel/>
                                  <Footer/>
                                </>
        }/>      
      </Routes>
    </Router>
    </ContactProvider>
  );
}

export default App;
