// ContactContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Url } from "./config/url";
import { api_token } from "./session";

// Crea el contexto
export const ContactContext = createContext();

// Crea el proveedor del contexto
export const ContactProvider = ({ children }) => {
  const [contact, setContact] = useState([]);
  const [carrousel, setCarrousel] = useState([]);
  const [socialNetwork, setSocialNetwork] = useState([]);
  const [footer, setFooter] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const contactResponse = await axios.get(
          `${Url}management/datos_hps`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${api_token}`,
          }
        });
        setContact(contactResponse.data);

        const socialNetworkResponse = await axios.get(
            `${Url}webconfigsocialnetwork`, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${api_token}`,
            }
          }
  
          );
          setSocialNetwork(socialNetworkResponse.data);

        const carrouselResponse = await axios.get(
          `${Url}webconfigmaincarousel`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${api_token}`,
          }
        }
        );
        setCarrousel(carrouselResponse.data);

        const footerResponse = await axios.get(
          `${Url}webconfigschedule`, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${api_token}`,
            }
          }
        );
        setFooter(footerResponse.data);
        
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [api_token, Url]);

  return (
    <ContactContext.Provider value={{ contact, carrousel, socialNetwork, footer }} >
      {children}
    </ContactContext.Provider>
  );
};